import { useFragment, graphql } from 'react-relay';

import { useShouldShowAddToCalendar_itemSearch$key } from './__generated__/useShouldShowAddToCalendar_itemSearch.graphql';

const VALID_BUTTON_PERIOD_IN_DAYS = 7;

const itemSearchFragment = graphql`
    fragment useShouldShowAddToCalendar_itemSearch on ItemSearchQueryConnection {
        publicationStartDate
        isCollectionAuction
    }
`;

export const useShouldShowAddToCalendar = ({
    itemSearch: itemSearchRef,
}: {
    itemSearch: useShouldShowAddToCalendar_itemSearch$key;
}): boolean | null => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const { isCollectionAuction, publicationStartDate: publicationDate } = itemSearch || {};
    const nowDate = new Date().getTime();

    if (!publicationDate) {
        return false;
    }
    const publicationStartDate = new Date(publicationDate);
    publicationStartDate.setDate(publicationStartDate.getDate() + VALID_BUTTON_PERIOD_IN_DAYS);
    //Event on calendar will start 30 minutes before. No need to show button in that case
    const buttonEndDate = publicationStartDate.setMinutes(publicationStartDate.getMinutes() - 30);
    const showAddCalendarButton = nowDate < buttonEndDate && isCollectionAuction;
    return showAddCalendarButton;
};
