import { FC, lazy } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ClientSuspense } from 'dibs-elements/src/ClientSuspense';

import { useShouldShowAddToCalendar } from '../hooks/auctions/useShouldShowAddToCalendar';

import { SbRespAddToCalendarLazy_itemSearch$key } from './__generated__/SbRespAddToCalendarLazy_itemSearch.graphql';

export const SbRespAddToCalendarLazyComponent = lazy(
    () => import(/* webpackChunkName: "SbRespAddToCalendar" */ './SbRespAddToCalendar')
);

const itemSearchFragment = graphql`
    fragment SbRespAddToCalendarLazy_itemSearch on ItemSearchQueryConnection {
        ...SbRespAddToCalendar_itemSearch
        ...useShouldShowAddToCalendar_itemSearch
    }
`;

export const SbRespAddToCalendarLazy: FC<{
    itemSearch: SbRespAddToCalendarLazy_itemSearch$key;
}> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const shouldShowAddToCalendar = useShouldShowAddToCalendar({ itemSearch });

    if (!shouldShowAddToCalendar) {
        return null;
    }

    return (
        <ClientSuspense fallback={null}>
            <SbRespAddToCalendarLazyComponent itemSearch={itemSearch} />
        </ClientSuspense>
    );
};
