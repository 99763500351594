/**
 * @generated SignedSource<<8b6bf0cf7a0cda176591ca122787ac47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespComponents_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly bottomModule: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModule_bottomModule">;
  } | null> | null;
  readonly internalBuyLinks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SharedRespLinksBlock_links">;
  } | null> | null;
  readonly isL1LandingPage: boolean | null;
  readonly pageType: string | null;
  readonly soldItems: {
    readonly totalResults: number | null;
  } | null;
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAddToCalendarLazy_itemSearch" | "SbRespCategoryTiles_itemSearch" | "SbRespContentModuleSelector_itemSearch" | "SbRespDesignTiles_itemSearch" | "SbRespFollowSearchBanner_itemSearch" | "SbRespInteriorsWordpressArticlesWrapper_itemSearch" | "SbRespL1Landing_itemSearch" | "SbRespMainContent_itemSearch" | "SbRespNullSearchResults_itemSearch" | "SbRespPriceTiles_itemSearch" | "SbRespTradeOnApprovalPromoBanner_itemSearch" | "SbSharedBillboardAd_itemSearch" | "SbSharedControlledHeadingWrapper_itemSearch" | "SbSharedForceSearchLink_itemSearch" | "SbSharedPageBreadcrumbs_itemSearch" | "SbSharedRelatedCreators_itemSearch" | "SbSharedRelatedSearches_itemSearch" | "SbSharedSearchSuggestions_itemSearch" | "SbSharedTitle_itemSearch" | "SbSharedTopTileController_itemSearch" | "SharedFAQ_itemSearch" | "SharedFollowTopButton_itemSearch" | "SharedRecentSales_itemSearch" | "SharedRelatedPages_itemSearch" | "SharedRespLinksBlock_itemSearch" | "SharedSaveSearchContext_itemSearch" | "SharedWordpressArticles_itemSearch" | "SharedYMAL_itemSearch">;
  readonly " $fragmentType": "SbRespComponents_itemSearch";
};
export type SbRespComponents_itemSearch$key = {
  readonly " $data"?: SbRespComponents_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespComponents_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespComponents_itemSearch",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isL1LandingPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BottomModule",
      "kind": "LinkedField",
      "name": "bottomModule",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedBottomModule_bottomModule"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespAddToCalendarLazy_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedControlledHeadingWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedForceSearchLink_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedSearchSuggestions_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespNullSearchResults_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTitle_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTopTileController_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedSearches_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespCategoryTiles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespDesignTiles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedSaveSearchContext_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedBillboardAd_itemSearch"
    },
    {
      "condition": "isTrade",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespPriceTiles_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespTradeOnApprovalPromoBanner_itemSearch"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespMainContent_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespContentModuleSelector_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsWordpressArticlesWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedWordpressArticles_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespL1Landing_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedPages_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedCreators_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRespLinksBlock_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalBuyLinkType",
      "kind": "LinkedField",
      "name": "internalBuyLinks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedRespLinksBlock_links"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFAQ_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_itemSearch"
    },
    {
      "alias": "soldItems",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 28
        }
      ],
      "concreteType": "soldResultsConnection",
      "kind": "LinkedField",
      "name": "soldResults",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "soldResults(first:28)"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedYMAL_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespFollowSearchBanner_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "a1e2ff3172fe1d7669096cde3800b2c9";

export default node;
