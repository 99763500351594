/**
 * @generated SignedSource<<bebb9e7e824601a36bfec148e4829888>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespAddToCalendarLazy_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAddToCalendar_itemSearch" | "useShouldShowAddToCalendar_itemSearch">;
  readonly " $fragmentType": "SbRespAddToCalendarLazy_itemSearch";
};
export type SbRespAddToCalendarLazy_itemSearch$key = {
  readonly " $data"?: SbRespAddToCalendarLazy_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAddToCalendarLazy_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespAddToCalendarLazy_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespAddToCalendar_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useShouldShowAddToCalendar_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "54e3d7a36a6c7deadacf8e81a29ec9ae";

export default node;
