import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { trackEcommerce, trackEvent, eventNameConstants } from 'dibs-tracking';
import { getCategories } from '../../utils/categoryHelpers';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { ECOMMERCE_ATTRIBUTES } from '../../constants/attributesConstants';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

const getPageLevel = ({ appliedFilters }) => {
    const categories = getCategories(appliedFilters);
    return categories.length;
};

class SbSharedCategoryTilesTrackingComponent extends Component {
    constructor() {
        super();

        this.trackingContainerRef = createRef();
        this.fireTracking = this.fireTracking.bind(this);
        this.trackClick = this.trackClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.itemSearch.displayUriRef !== this.props.itemSearch.displayUriRef) {
            this.fireTracking();
        }
    }

    fireTracking() {
        const { itemSearch } = this.props;

        if (itemSearch.pageType === pageTypes.SEARCH) {
            trackEvent({
                category: 'promo interaction',
                action: 'broad search tiles displayed',
                label: `l${getPageLevel(itemSearch)}`,
            });
        } else {
            trackEcommerce({
                type: 'promotionImpression',
                eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
                promotions: this.getTrendingPromotions(),
            });
        }
    }

    trackClick(index) {
        const { itemSearch } = this.props;

        if (itemSearch.pageType === pageTypes.SEARCH) {
            trackEvent({
                category: 'promo interaction',
                action: 'broad search tiles clicked',
                label: `l${getPageLevel(itemSearch)}`,
                isInteractiveEvent: true,
            });
        } else {
            const promotions = this.getTrendingPromotions();
            trackEcommerce({
                type: 'promoClick',
                eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
                promotions: [promotions[index]],
            });
        }

        const categoryTile = (itemSearch.categoryTiles || [])[index] || {};
        if ((categoryTile.url || '').startsWith('/auctions/')) {
            trackEvent({
                category: 'ecommerce',
                action: 'promotion click',
                label: `l${getPageLevel(itemSearch)}|auction tile`,
                isInteractiveEvent: true,
            });
        }
    }

    getTrendingPromotions() {
        const { itemSearch, maxTilesAllowed } = this.props;
        const { appliedFilters = [], categoryTiles = [], pageType } = itemSearch;

        let tiles = categoryTiles || [];
        tiles = maxTilesAllowed ? categoryTiles?.slice(0, maxTilesAllowed) : categoryTiles;

        const isSalePage = pageType === pageTypes.SALE;
        const isAuctionPage = pageType === pageTypes.AUCTION;
        // for auction page getPageLevel returns 0
        const pageLevel = isAuctionPage ? 1 : getPageLevel(itemSearch);

        const attributes = appliedFilters.filter(({ name }) => ECOMMERCE_ATTRIBUTES.includes(name));
        let trackingId = `L${pageLevel + 1}`;
        let trackingNameType = 'Result';

        if (attributes.length) {
            const attribute = attributes?.[0];
            const attributeName = attribute?.name;
            const attributeValue = attribute?.values?.[0]?.urlLabel;
            trackingId =
                attributes.length > 1 ? 'multi-attribute' : `${attributeName}:${attributeValue}`;
            trackingNameType = 'Attribute';
        }

        let name = `L${pageLevel}|${trackingNameType} Tiles`;

        if (isSalePage) {
            trackingId = 'sale';
            const pageLevelDisplayText =
                pageLevel === 0 ? 'root page' : `L${pageLevel} ${trackingNameType} Tiles`;
            name = `Sale | ${pageLevelDisplayText}`;
        }

        return tiles.map((tile, i) => ({
            creative: isSalePage ? `sale - ${tile.displayName}` : tile.displayName,
            id: `${trackingId}-result-tiles`,
            name,
            position: i + 1,
            itemId: tile.itemId,
        }));
    }

    render() {
        const { trackClick, trackingContainerRef } = this;
        return (
            <div ref={trackingContainerRef}>
                <VisibilityTracker
                    elementRef={trackingContainerRef}
                    onVisibilityChange={({ isVisible }) => {
                        if (isVisible) {
                            this.fireTracking();
                        }
                    }}
                />
                {this.props.children({ trackClick })}
            </div>
        );
    }
}

SbSharedCategoryTilesTrackingComponent.propTypes = {
    maxTilesAllowed: PropTypes.number,
    children: PropTypes.func.isRequired,
    itemSearch: PropTypes.object.isRequired,
};

export const SbSharedCategoryTilesTracking = createFragmentContainer(
    SbSharedCategoryTilesTrackingComponent,
    {
        itemSearch: graphql`
            fragment SbSharedCategoryTilesTracking_itemSearch on ItemSearchQueryConnection {
                displayUriRef
                pageType
                categoryTiles {
                    displayName
                    itemId
                    url
                }
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
    }
);
