/**
 * @generated SignedSource<<3e04bbdffd38ffe778723e1e0d284707>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespComponents_auctionsItemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespMainContent_auctionsItemSearch">;
  readonly " $fragmentType": "SbRespComponents_auctionsItemSearch";
};
export type SbRespComponents_auctionsItemSearch$key = {
  readonly " $data"?: SbRespComponents_auctionsItemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespComponents_auctionsItemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespComponents_auctionsItemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespMainContent_auctionsItemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "205f0c46e19bf36462aa6da308317a0a";

export default node;
