import { useRef } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { getBuyerId, getGuestId } from 'dibs-cookie-jar';
import { sbRespRefineMenuFiltersMap } from '../SbRespRefineMenu/SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';
import { hasPersonalizedFilterQueryParam } from '../../shared/helpers/personalizedFiltersHelpers';
import { handleSbUserCountryCode } from '../helpers/handleSbUserCountryCode';
import { getEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';
import { isUserEngagementBoostAbTestVariantOrControl } from '../../utils/abTestHelper';
import { isSearchOrBuyOrAuctionPage } from '../../utils/categoryHelpers';

/* Components */
import { handlePageRefetchVariableOnUserChange } from '../../shared/handlePageRefetchVariableOnUserChange';
import { SbSharedRefetchContainer } from '../SbSharedRefetchContainer/SbSharedRefetchContainer';
import { SbRespComponents } from '../SbRespComponents/SbRespComponents';
import { SbSharedHead } from '../SbSharedHead/SbSharedHead';
import { SbSharedTrackingContainer } from '../SbSharedTrackingContainer/SbSharedTrackingContainer';
import { RecentlyViewedItemsProvider } from 'dibs-recent-history/exports/RecentlyViewedItemsProvider';

/* Hooks */
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { useSharedUserAndRelayVarsLoader } from '../../shared/SharedUserAndRelayVarLoader';
import { useSearchCorrections } from '../useSearchCorrections';
import { useSbSelector } from '../../reducers/useSbSelector';

const SbRespLayoutComponent = ({
    viewer,
    reinitTracking,
    relay,
    runNotificationQueue = () => {},
}) => {
    const {
        itemSearch,
        auctionsItemSearch = null,
        regionalInfo = null,
        shippingFilters = null,
        user = null,
    } = viewer;
    const relayVars = useRef({});
    const { isTablet } = useServerVarsContext();
    const relayVariables = useSbSelector(store => store.relayVariables.variables);
    const [searchCorrectionTerm] = useSearchCorrections({ itemSearch });

    function doRefetch(variables, renderVariables, callback) {
        relayVars.current = {
            ...relayVars.current,
            ...variables,
            userCountryCode: handleSbUserCountryCode({
                userCountryCode: variables?.userCountryCode,
                fetchSb: variables?.fetchSb,
            }),
        };
        relay.refetch(
            relayVars.current,
            { ...relayVars.current, ...renderVariables, fetchSb: true },
            callback
        );
    }

    function onUserRefetch(variables, ...args) {
        const { isClient, fetchSbOnUserChange } = variables || {};
        const hasPersonalizedFilter = isClient && hasPersonalizedFilterQueryParam();

        //Personal-rerank needs to refetch s&b after login
        const fetchSb = handlePageRefetchVariableOnUserChange(variables) || fetchSbOnUserChange;

        const { displayUriRef } = itemSearch;
        const { followSearchPages } = relayVariables || {};

        doRefetch(
            {
                ...variables,
                fetchSb,
                skipUser: false,
                hasPersonalizedFilter,
                followSearchPages: displayUriRef ? [displayUriRef] : followSearchPages,
            },
            ...args
        );
    }

    function handleSbRefetch(variables, renderVariables, callback) {
        /*
         * UserData is required for sponsored listings, add it to relay vars for refetch,
         * cause initial page could be not eligible for sponsored items, but subsequent page could (eg. by removing filters).
         * graphql will decide if sponsored items should be added in the end.
         */
        const userData = {
            personalizationId: getBuyerId(document.cookie) || '',
            guestId: getGuestId(document.cookie) || '',
            includeSponsoredItems: true,
        };

        if (
            isUserEngagementBoostAbTestVariantOrControl() &&
            !isSearchOrBuyOrAuctionPage(variables.uriRef)
        ) {
            userData.engagedItems = getEngagedItems();
        }

        doRefetch({ ...variables, ...userData, fetchSb: true }, renderVariables, () => {
            callback();

            runNotificationQueue();
        });
    }

    useSharedUserAndRelayVarsLoader({ onUserRefetch });

    return (
        <RecentlyViewedItemsProvider maxItems={isTablet ? 10 : 12} itemsPerPage={isTablet ? 5 : 6}>
            <SbSharedTrackingContainer
                viewer={viewer}
                user={user}
                itemSearch={itemSearch}
                reinitTracking={reinitTracking}
                refineMenuFiltersMap={sbRespRefineMenuFiltersMap}
                searchCorrectionTerm={searchCorrectionTerm}
            />
            <SbSharedHead itemSearch={itemSearch} />
            <SbSharedRefetchContainer
                viewer={viewer}
                itemSearch={itemSearch}
                regionalInfo={regionalInfo}
                shippingFilters={shippingFilters}
                onSbRefetch={handleSbRefetch}
                shouldShowSpinner={false}
            />
            <SbRespComponents
                viewer={viewer}
                itemSearch={itemSearch}
                auctionsItemSearch={auctionsItemSearch}
                user={user}
                isClient={!!relayVars.current.isClient}
                shippingFilters={shippingFilters}
            />
        </RecentlyViewedItemsProvider>
    );
};

SbRespLayoutComponent.propTypes = {
    viewer: PropTypes.object,
    relay: PropTypes.object.isRequired,
    runNotificationQueue: PropTypes.func.isRequired,
    reinitTracking: PropTypes.func,
};

export const SbRespLayout = createRefetchContainer(
    SbRespLayoutComponent,
    {
        viewer: graphql`
            fragment SbRespLayout_viewer on Viewer
            @argumentDefinitions(
                hasPersonalizedFilter: { type: "Boolean", defaultValue: false }
                userZipCode: { type: "String", defaultValue: "" }
                fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            ) {
                ...SbRespComponents_viewer
                ...SbSharedTrackingContainer_viewer
                itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    originalUrl: $originalUrl
                    first: $first
                    page: $page
                    localeOrigin: $localeOrigin
                    regions: $regions
                    disableForceFacet: $disableForceFacet
                    disableNonParameterizedUrlRedirects: $disableNonParameterizedUrlRedirects
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    includeSponsoredItems: $includeSponsoredItems
                    priceBookName: $priceBookName
                    engagedItems: $engagedItems
                ) {
                    displayUriRef
                    pageType
                    # Used by SearchCorrectionsProvider
                    ...useSearchCorrections_itemSearch
                    ...SbSharedRefetchContainer_itemSearch
                    ...SbSharedTrackingContainer_itemSearch
                    ...SbSharedHead_itemSearch
                    ...SbRespComponents_itemSearch @include(if: $fetchSb)
                    ...SbRespFollowSearchBanner_itemSearch
                }
                auctionsItemSearch: itemSearch(
                    uriRef: $auctionsItemSearchUriRef
                    userId: $personalizationId
                    guestId: $guestId
                ) @include(if: $fetchAuctionsItemSearch) {
                    ...SbRespComponents_auctionsItemSearch
                }
                user(userId: $userId) @include(if: $fetchUser) {
                    ...SbSharedTrackingContainer_user
                    ...SbRespComponents_user
                }
                regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
                    @include(if: $fetchRegionalInfo) {
                    ...SbSharedRefetchContainer_regionalInfo
                }
                shippingFilters(
                    uriRef: $uriRef
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $hasPersonalizedFilter) {
                    ...SbSharedRefetchContainer_shippingFilters
                    ...SbRespComponents_shippingFilters
                }
            }
        `,
    },
    graphql`
        query SbRespLayoutRefetchQuery(
            $userId: String = ""
            $personalizationId: String = ""
            $guestId: String = ""
            $rerankUserId: String = ""
            $rerankGuestId: String = ""
            $fetchUser: Boolean!
            $fetchSb: Boolean!
            $skipUser: Boolean = true
            $isTrade: Boolean!
            $includeSponsoredItems: Boolean = false
            $uriRef: String
            $originalUrl: String
            $first: Int!
            $page: Int!
            $localeOrigin: String = ""
            $showSeller: Boolean!
            $followSearchPages: [String]
            $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
            $contentModuleId: String = ""
            $previewId: String = ""
            $regions: String = ""
            $hasPersonalizedFilter: Boolean!
            $userZipCode: String = ""
            $userCountryCode: String = ""
            $fetchRegionalInfo: Boolean = false
            $disableForceFacet: Boolean = true
            $isClient: Boolean!
            $disableNonParameterizedUrlRedirects: Boolean = false # Only used for static SB
            $fetchTileVideo: Boolean = false
            # AUCTIONS-1264 - Changing this argument might break carousel UI, make sure the requirements in the ticket description are met.
            $interiorPhotosCount: Int = 10
            $priceBookName: String
            $fetchAuctionsItemSearch: Boolean!
            $auctionsItemSearchUriRef: String
            $regionsList: [String]
            $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
            $engagedItems: [String]
        ) {
            viewer {
                ...SbRespComponents_viewer
                ...SbSharedTrackingContainer_viewer
                itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    originalUrl: $originalUrl
                    first: $first
                    page: $page
                    localeOrigin: $localeOrigin
                    regions: $regions
                    disableForceFacet: $disableForceFacet
                    disableNonParameterizedUrlRedirects: $disableNonParameterizedUrlRedirects
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                    includeSponsoredItems: $includeSponsoredItems
                    engagedItems: $engagedItems
                ) @include(if: $fetchSb) {
                    displayUriRef
                    pageType
                    ...SbSharedRefetchContainer_itemSearch
                    ...SbRespComponents_itemSearch
                    ...SbSharedHead_itemSearch
                    ...SbSharedTrackingContainer_itemSearch
                }
                auctionsItemSearch: itemSearch(
                    uriRef: $auctionsItemSearchUriRef
                    userId: $personalizationId
                    guestId: $guestId
                ) @include(if: $fetchAuctionsItemSearch) {
                    ...SbRespComponents_auctionsItemSearch
                }
                user(userId: $userId) @include(if: $fetchUser) @skip(if: $skipUser) {
                    ...SbSharedTrackingContainer_user
                    ...SbRespComponents_user
                }
                regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
                    @include(if: $fetchRegionalInfo) {
                    ...SbSharedRefetchContainer_regionalInfo
                }
                shippingFilters(
                    uriRef: $uriRef
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $hasPersonalizedFilter) {
                    ...SbSharedRefetchContainer_shippingFilters
                    ...SbRespComponents_shippingFilters
                }
            }
        }
    `
);
