import { FC, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { trackPromoImpressions, trackPromoClick } from '../helpers/sbSharedFeaturedTracking';

// components
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { SbSharedL1FeaturedTile } from '../SbSharedL1FeaturedTile/SbSharedL1FeaturedTile';

// types
import { SbSharedL1ModuleTile_l1ModuleItemTile$key } from './__generated__/SbSharedL1ModuleTile_l1ModuleItemTile.graphql';

type CreativeName =
    | 'Right Tile'
    | 'Middle Tile'
    | 'Left Tile'
    | 'L1 auction banner'
    | 'L1 collections'
    | 'price bucket';

type Props = {
    l1ModuleItemTile: SbSharedL1ModuleTile_l1ModuleItemTile$key | null | undefined;
    isBannerTile?: boolean;
    creative: CreativeName;
    position?: number;
};

const l1ModuleItemTileFragment = graphql`
    fragment SbSharedL1ModuleTile_l1ModuleItemTile on L1ModuleItemTile {
        dek
        header
        linkData {
            ...SeoLink_linkData
        }
        mainImage
    }
`;

export const SbSharedL1ModuleTile: FC<Props> = ({
    l1ModuleItemTile: l1ModuleItemTileRef,
    position,
    creative,
    isBannerTile,
}) => {
    const l1ModuleItemTile = useFragment(l1ModuleItemTileFragment, l1ModuleItemTileRef);
    const { linkData, mainImage, header, dek } = l1ModuleItemTile || {};

    useEffect(() => {
        trackPromoImpressions({ promotions: [creative], position });
    }, [creative, position]);

    const featuredTileProps = {
        fullWidth: isBannerTile,
        mainImage: mainImage,
        heading: header,
        subHeading: dek,
    };
    return (
        <SbSharedL1FeaturedTile {...featuredTileProps}>
            {linkData
                ? ({ className, children }) => (
                      <SeoLink
                          className={className}
                          linkData={linkData}
                          onClick={() => trackPromoClick({ promotion: creative, position })}
                      >
                          {children}
                      </SeoLink>
                  )
                : undefined}
        </SbSharedL1FeaturedTile>
    );
};
