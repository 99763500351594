import get from 'lodash.get';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';

// components
import { SbSharedPriceTile } from '../SbSharedPriceTile/SbSharedPriceTile';
import { SbRespTilesSwiper } from '../SbRespTilesSwiper/SbRespTilesSwiper';
import { ServerVarsContextConsumer } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespTradeFreeShippingPromoTile } from '../SbRespTradeFreeShippingPromoTile/SbRespTradeFreeShippingPromoTile';

import styles from './SbRespPriceTiles.scss';

const MAX_DISPLAY_PRICE_TILES = 4;

function getPriceTilesForCurrency(tiles, currency) {
    return tiles.filter(t => !!t && t.currency === currency);
}

export const SbRespPriceTilesComponent = props => {
    const { itemSearch, viewer } = props;
    const { displayUriRef } = itemSearch;
    const priceTiles = get(itemSearch, 'priceTiles') || [];

    if (!priceTiles.length) {
        return null;
    }

    return (
        <ServerVarsContextConsumer>
            {({ isTablet }) => (
                <div className={styles.container}>
                    <CurrencyProvider
                        render={({ currency }) => (
                            <SbRespTilesSwiper displayUriRef={displayUriRef} isTablet={isTablet}>
                                {[
                                    ...getPriceTilesForCurrency(priceTiles, currency)
                                        .slice(0, MAX_DISPLAY_PRICE_TILES)
                                        .map((priceTile, i) => {
                                            const { displayName, image, url } = priceTile;
                                            const indexLabel = i + 1;
                                            return (
                                                <SbSharedPriceTile
                                                    displayName={displayName}
                                                    hoverMessage={
                                                        <FormattedMessage
                                                            id="sb.SbRespPriceTiles.shop"
                                                            defaultMessage="shop"
                                                        />
                                                    }
                                                    image={image}
                                                    isPriceBucket
                                                    key={`priceTile-${displayName}`}
                                                    position={indexLabel}
                                                    promoLabel={`Trade Price Range ${indexLabel}`}
                                                    itemSearch={itemSearch}
                                                    url={url}
                                                />
                                            );
                                        }),
                                    <SbRespTradeFreeShippingPromoTile
                                        key="trade-free-shipping-tile"
                                        currency={currency}
                                        position={5}
                                        itemSearch={itemSearch}
                                        viewer={viewer}
                                    />,
                                ]}
                            </SbRespTilesSwiper>
                        )}
                    />
                </div>
            )}
        </ServerVarsContextConsumer>
    );
};

SbRespPriceTilesComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object,
};

export const SbRespPriceTiles = createFragmentContainer(SbRespPriceTilesComponent, {
    itemSearch: graphql`
        fragment SbRespPriceTiles_itemSearch on ItemSearchQueryConnection {
            ...SbSharedPriceTile_itemSearch
            ...SbRespTradeFreeShippingPromoTile_itemSearch
            displayUriRef
            priceTiles(isTrade: $isTrade) {
                currency
                displayName
                image
                url
            }
        }
    `,
    viewer: graphql`
        fragment SbRespPriceTiles_viewer on Viewer {
            ...SbRespTradeFreeShippingPromoTile_viewer
        }
    `,
});
