import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

// components
import { SbSharedLink } from '../SbSharedLink/SbSharedLink';
import { SbSharedCategoryTilesTracking } from '../SbSharedCategoryTilesTracking/SbSharedCategoryTilesTracking';
import { SbRespTilesSwiper } from '../SbRespTilesSwiper/SbRespTilesSwiper';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

// utils
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { createNewUriRef, getQueryParam } from '../../utils/uriUtils';

//constants
import { SRC_SET_SIZES_RESP_CAROUSEL_TILE } from '../../constants/imageConstants';
import { SORT } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { pageTypeConstants } from '../../constants/pageTypeConstants';

import styles from './sbRespCategoryTiles.scss';

export const SbRespCategoryTilesComponent = props => {
    const { isTablet } = useServerVarsContext();
    const { itemSearch } = props;
    const { displayUriRef, isL1LandingPage, pageType } = itemSearch;
    const isNotAuctionPage = pageTypeConstants.AUCTION !== pageType;

    /**
     * We show 5 tiles max.
     *
     * Special case - for fashion we only show 4, but that is already handled in graphql, so we
     * don't need to handle it here.
     *
     */

    // Max tiles counter is increased for the test ENGAGE-1541.
    const maxTilesCount = isL1LandingPage ? 6 : 5;

    const tiles = itemSearch?.categoryTiles?.slice(0, maxTilesCount) || [];

    const refetchInFlight = useSelector(state => state.filters.refetchInFlight);
    const sort = getQueryParam(displayUriRef, SORT);

    if (!tiles.length) {
        return null;
    }

    const containerClass = classnames(styles.container, {
        rowFlex: !isTablet,
        [styles.loading]: refetchInFlight,
        [styles.isL1LandingPage]: isL1LandingPage,
    });

    return (
        <SbSharedCategoryTilesTracking itemSearch={itemSearch} maxTilesAllowed={maxTilesCount}>
            {({ trackClick }) => (
                <div className={containerClass} data-tn="category-tiles">
                    <SbRespTilesSwiper displayUriRef={displayUriRef} isTablet={isTablet}>
                        {tiles.map((categoryTile, index) => {
                            const { displayName, url, image } = categoryTile;
                            // to persist the sort query in the category tile link
                            const href = sort ? createNewUriRef(url, { sort }) : url;
                            const isAuctionTile = url?.includes('auction');
                            const itemContainerClasses = classnames(
                                styles.itemContainer,
                                'colXs3',
                                // For some css reason "nth-child" directive is not overwritten,
                                // so setting either one or another limit class.
                                styles.itemLimit,
                                {
                                    [styles.auctionTile]: isAuctionTile && isNotAuctionPage,
                                }
                            );

                            return (
                                <SbSharedLink
                                    className={itemContainerClasses}
                                    href={href}
                                    onClick={() => trackClick(index)}
                                    key={displayName}
                                >
                                    <div className={styles.imageWrapper}>
                                        <img
                                            className={styles.image}
                                            src={image}
                                            alt={displayName}
                                            srcSet={getSrcsetString(image)}
                                            sizes={SRC_SET_SIZES_RESP_CAROUSEL_TILE}
                                        />
                                    </div>
                                    <div className={styles.title}>{displayName}</div>
                                </SbSharedLink>
                            );
                        })}
                    </SbRespTilesSwiper>
                </div>
            )}
        </SbSharedCategoryTilesTracking>
    );
};

SbRespCategoryTilesComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
};

export const SbRespCategoryTiles = createFragmentContainer(SbRespCategoryTilesComponent, {
    itemSearch: graphql`
        fragment SbRespCategoryTiles_itemSearch on ItemSearchQueryConnection {
            ...SbSharedCategoryTilesTracking_itemSearch
            displayUriRef
            pageType
            isL1LandingPage
            categoryTiles {
                displayName
                url
                image
            }
        }
    `,
});
