import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

// components
import { FormattedMessage } from 'dibs-react-intl';
import { hasKey } from 'dibs-ts-utils/src/hasKey';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { SbSharedFeaturedDeal } from '../SbSharedFeaturedDeal/SbSharedFeaturedDeal';
import { SbSharedL1ModuleTile } from '../SbSharedL1ModuleTile/SbSharedL1ModuleTile';
import { SbSharedL1ModuleBanner } from '../SbSharedL1ModuleBanner/SbSharedL1ModuleBanner';
import { SbRespFeaturedTrendingLazy } from '../SbRespFeaturedTrending/SbRespFeaturedTrendingLazy';
import { shopVertical } from '../sbMessages';

// types
import { SbRespL1Landing_itemSearch$key as ItemSearch } from './__generated__/SbRespL1Landing_itemSearch.graphql';

//styles
import styles from './SbRespL1Landing.scss';

type Props = {
    itemSearch: ItemSearch;
};

const itemSearchFragment = graphql`
    fragment SbRespL1Landing_itemSearch on ItemSearchQueryConnection {
        ...SbSharedFeaturedDeal_itemSearch
        pageType
        topCategoryL1
        meta {
            header
            subHeader
        }
        l1Module(previewId: $previewId) {
            ...SbSharedL1ModuleBanner_l1Module
            firstTile {
                ...SbSharedL1ModuleTile_l1ModuleItemTile
            }
            secondTile {
                ...SbSharedL1ModuleTile_l1ModuleItemTile
            }
        }
    }
`;

export const SbRespL1Landing: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const { meta, topCategoryL1, l1Module, pageType } = itemSearch || {};
    const { header, subHeader } = meta || {};
    const isAuctionPage = pageType === pageTypes.AUCTION;

    if (!topCategoryL1) {
        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <h1 className={styles.header} data-tn="header-title">
                        {header}
                    </h1>
                    <HeadingLevel>
                        {Heading => (
                            <Heading className={styles.subHeader} dataTn="header-sub-title">
                                {subHeader}
                            </Heading>
                        )}
                    </HeadingLevel>
                </div>
                {l1Module && (
                    <div className={styles.topModule}>
                        {isAuctionPage ? (
                            <SbSharedL1ModuleTile
                                l1ModuleItemTile={l1Module?.firstTile || null}
                                creative="Left Tile"
                            />
                        ) : (
                            <CurrencyProvider
                                render={({ currency }: { currency: string }) => (
                                    <SbSharedFeaturedDeal
                                        currency={currency}
                                        itemSearch={itemSearch}
                                    />
                                )}
                            />
                        )}
                        {
                            <>
                                <SbSharedL1ModuleBanner l1Module={l1Module} />
                                <SbSharedL1ModuleTile
                                    l1ModuleItemTile={l1Module?.secondTile || null}
                                    creative="Right Tile"
                                />
                            </>
                        }
                    </div>
                )}
            </div>
            {!isAuctionPage && (
                <div className={styles.lowerModuleContainer}>
                    <div className={styles.lowerModule}>
                        <SbRespFeaturedTrendingLazy
                            vertical={topCategoryL1}
                            isAuctionPage={isAuctionPage}
                        />
                    </div>
                </div>
            )}
            <HeadingLevel>
                {Heading => (
                    <Heading
                        className={`${styles.shop} ${
                            isAuctionPage ? styles.auctionsShopHeader : undefined
                        }`}
                    >
                        {isAuctionPage ? (
                            <FormattedMessage
                                id="SbRespL1Landing.more"
                                defaultMessage="More Categories to Browse"
                            />
                        ) : (
                            hasKey(shopVertical, topCategoryL1) && shopVertical[topCategoryL1]
                        )}
                    </Heading>
                )}
            </HeadingLevel>
        </>
    );
};
